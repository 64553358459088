import React, { useEffect, useState } from "react";
import { Link } from "gatsby";
import { MenuButton } from "../icons/icons";
import "./menu-button.scss";
import "./header.scss";

const Header = () => {
  const [activeMenu, setActiveMenu] = useState(false);

  function handleMenuClick() {
    setActiveMenu((prevCheck) => !prevCheck);
  }

  function handleCloseOnCurrent(e) {
    if (activeMenu) {
      if (e.target.ariaCurrent === "page") setActiveMenu(false);
    }

    return;
  }

  useEffect(() => {
    document.body.classList.toggle("no-scroll", activeMenu);

    return () => {
      document.body.classList.remove("no-scroll");
    };
  }, [activeMenu]);

  return (
    <header id="header">
      <div className="header-wrapper">
        <Link className="header-logo" to="/">
          <div className="header-logo-wrap">
            <span className="logo-block can">
              <img src={`../../atlfp-logo-can.png`} alt="ATL Film Party Logo" />
            </span>
          </div>
          <div className="header-title">
            <h1>AFPZine</h1>
          </div>
        </Link>
        <div
          className={`header-content ${activeMenu === true ? "active" : ""}`}
          aria-label="Site Navigation"
        >
          <nav className="header-links">
            <ul className="link-list">
              <li className="menu-item">
                <Link
                  onClick={(e) => handleCloseOnCurrent(e)}
                  to="/about"
                  activeStyle={{ color: "#F82645" }}
                >
                  About
                </Link>
              </li>
              <li className="menu-item">
                <Link
                  onClick={(e) => handleCloseOnCurrent(e)}
                  to="/calendar"
                  activeStyle={{ color: "#F82645" }}
                >
                  Calendar
                </Link>
              </li>
              <li className="menu-item">
                <Link
                  onClick={(e) => handleCloseOnCurrent(e)}
                  to="/faqs"
                  activeStyle={{ color: "#F82645" }}
                >
                  FAQ
                </Link>
              </li>
              <li className="menu-item">
                <Link
                  onClick={(e) => handleCloseOnCurrent(e)}
                  to="/call-for-entries"
                  activeStyle={{ color: "#F82645" }}
                >
                  Call For Entries
                </Link>
              </li>
            </ul>
          </nav>
        </div>
        <div>
          <button
            className={`menu-trigger ${activeMenu === true ? "active" : ""}`}
            onClick={() => handleMenuClick()}
            name="Navigation Menu"
            type="button"
          >
            <MenuButton />
          </button>
        </div>
      </div>
    </header>
  );
};

export default Header;
