const React= require('react');
const { CookiesProvider } = require('react-cookie');
const Header = require('./src/components/header/header');
const {GlobalLayout} = require('./src/components/global-layout/global-layout');

exports.wrapRootElement = ({ element }) => {
    return (
      <CookiesProvider defaultSetOptions={{ path: '/' }}>
        {element}
      </CookiesProvider>
    )
  }

  exports.wrapPageElement = ({element}) => {
    return <GlobalLayout>{element}</GlobalLayout>;
  }