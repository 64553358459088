import React, { useState } from "react";
import "./mailchimp-signup.scss";

export const MailchimpSignup = () => {
  const [chimpEmail, setChimpEmail] = useState("");
  // const [submitResponse, setSubmitResponse] = useState("");

  return (
    <>
      <div id="mc_embed_shell">
        <div id="mc_embed_signup">
          <form
            action="https://atlfilmparty.us14.list-manage.com/subscribe/post?u=fdbb08f5c794827a61264a04d&amp;id=f0ff5c4e81&amp;f_id=00c54fe0f0"
            method="post"
            target="_blank"
            rel="noreferrer"
          >
            <div className="signup-field">
              <div id="mc_embed_signup_scroll">
                <div className="form-info">
                  <h4>Subscribe to AFP</h4>
                  <p>
                    Get the latest news & updates from AFP straight to your
                    inbox
                  </p>
                </div>
                <div className="form-fields">
                  <div className="mc-field-group">
                    <input
                      type="email"
                      name="EMAIL"
                      onChange={(e) => setChimpEmail(e.target.value)}
                      className="required email chimp-input"
                      // id="mce-EMAIL"
                      value={chimpEmail}
                      aria-label="Newsletter signup form"
                    />
                    {/* <span
                      id="mce-EMAIL-HELPERTEXT"
                      className="helper_text"
                    ></span> */}
                  </div>
                  <div className="optionalParent">
                    <div className="clear foot">
                      <input
                        type="submit"
                        name="subscribe"
                        // onSubmit={handleSubmit(chimpEmail)}
                        // id="mc-embedded-subscribe"
                        className="button chimp-input-button"
                        placeholder="Subscribe"
                      />
                    </div>
                  </div>
                </div>
                <div
                  aria-hidden="true"
                  style={{ position: "absolute", left: "-5000px" }}
                >
                  {/* real people should not fill this in and expect good things -
                  do not remove this or risk form bot signups */}
                  <input
                    type="text"
                    name="b_ad09e19e83571392805e04cf6_c135f5f7e4"
                    tabIndex="-1"
                    defaultValue=""
                  />
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};
