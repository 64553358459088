import React from "react";
import {
  IgIcon,
  TikTokIcon,
  LinkedInIcon,
  TwitterIcon,
  FacebookIcon,
} from "../icons/icons";
import "../share-bar/share-bar.scss";

const SocialRow = () => {
  return (
    <div className="share-bar">
      <div className="share-wrapper">
        <a
          className="share-item"
          aria-label="Follow us on Instagram"
          target="_blank"
          rel="noreferrer"
          href="https://www.instagram.com/atlfilmparty"
        >
          <IgIcon />
        </a>
        <a
          className="share-item"
          aria-label="Follow us on TikTok"
          target="_blank"
          rel="noreferrer"
          href="https://www.tiktok.com/@atlfilmparty"
        >
          <TikTokIcon />
        </a>
        <a
          className="share-item"
          aria-label="Follow us on LinkedIn"
          target="_blank"
          rel="noreferrer"
          href="https://www.linkedin.com/company/atlfilmparty/"
        >
          <LinkedInIcon />
        </a>
        <a
          className="share-item"
          aria-label="Follow us on Twitter"
          target="_blank"
          rel="noreferrer"
          href="https://twitter.com/atlfilmparty"
        >
          <TwitterIcon />
        </a>
        <a
          className="share-item"
          aria-label="Follow us on Facebook"
          target="_blank"
          rel="noreferrer"
          href="https://www.facebook.com/atlfilmparty/"
        >
          <FacebookIcon />
        </a>
      </div>
    </div>
  );
};

export default SocialRow;
