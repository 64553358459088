import React, {useEffect} from 'react';
// import {useCookies} from 'react-cookie';
import Header from '../header/header';
import Footer from '../footer/footer';

export const GlobalLayout = ({children}) => {
    // const [cookies, setCookie] = useCookies(['show-ticker']);

    // const today = new Date();
    // const futureDate = new Date(today);
    // const expiration = futureDate.setDate(today.getDate() + 30);


    // useEffect(() => {
    //     setCookie('show-ticker', 'false', [{path: '/', maxAge: '2592000'}])
    // }, [])

    return (
        <>
        <Header />
        {children}
        <Footer />
        </>
    )
}