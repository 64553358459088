import React from "react";
import "../share-bar/share-bar.scss";

export const ShareIcon = () => {
  return (
    <>
      <div className="social-icon">
        <svg x="0px" y="0px" viewBox="0 0 24 24">
          <path
            d="M21.5,7.1c0,0.2,0,0.4,0,0.6c0,6.5-5,14-14,14v0c-2.7,0-5.3-0.8-7.5-2.2c2.6,0.3,5.2-0.4,7.3-2c-2.1,0-4-1.4-4.6-3.4
	c0.7,0.1,1.5,0.1,2.2-0.1C2.6,13.5,1,11.5,1,9.2V9.1c0.7,0.4,1.5,0.6,2.2,0.6C1,8.3,0.4,5.4,1.7,3.1c2.5,3.1,6.2,4.9,10.1,5.1
	c-0.6-2.7,1-5.3,3.7-5.9C17.2,2,19,2.5,20.2,3.8c1.1-0.2,2.2-0.6,3.1-1.2c-0.4,1.1-1.1,2.1-2.2,2.7c1-0.1,1.9-0.4,2.8-0.8
	C23.3,5.5,22.5,6.4,21.5,7.1z"
          />
        </svg>
      </div>
    </>
  );
};

export const IgIcon = () => {
  return (
    <>
      <div className="social-icon">
        <svg x="0px" y="0px" viewBox="0 0 24 24">
          <path
            d="M12,2.2c3.2,0,3.6,0,4.9,0.1c3.3,0.1,4.8,1.7,4.9,4.9c0.1,1.3,0.1,1.6,0.1,4.8c0,3.2,0,3.6-0.1,4.8
	c-0.1,3.2-1.7,4.8-4.9,4.9c-1.3,0.1-1.6,0.1-4.9,0.1c-3.2,0-3.6,0-4.8-0.1c-3.3-0.1-4.8-1.7-4.9-4.9c-0.1-1.3-0.1-1.6-0.1-4.8
	c0-3.2,0-3.6,0.1-4.8c0.1-3.2,1.7-4.8,4.9-4.9C8.4,2.2,8.8,2.2,12,2.2z M12,0C8.7,0,8.3,0,7.1,0.1c-4.4,0.2-6.8,2.6-7,7
	C0,8.3,0,8.7,0,12s0,3.7,0.1,4.9c0.2,4.4,2.6,6.8,7,7C8.3,24,8.7,24,12,24s3.7,0,4.9-0.1c4.4-0.2,6.8-2.6,7-7C24,15.7,24,15.3,24,12
	s0-3.7-0.1-4.9c-0.2-4.4-2.6-6.8-7-7C15.7,0,15.3,0,12,0z M12,5.8c-3.4,0-6.2,2.8-6.2,6.2s2.8,6.2,6.2,6.2s6.2-2.8,6.2-6.2
	C18.2,8.6,15.4,5.8,12,5.8z M12,16c-2.2,0-4-1.8-4-4c0-2.2,1.8-4,4-4s4,1.8,4,4C16,14.2,14.2,16,12,16z M18.4,4.2
	c-0.8,0-1.4,0.6-1.4,1.4S17.6,7,18.4,7c0.8,0,1.4-0.6,1.4-1.4S19.2,4.2,18.4,4.2z"
          />
        </svg>
      </div>
    </>
  );
};

export const TikTokIcon = () => {
  return (
    <>
      <div className="social-icon">
        <svg x="0px" y="0px" viewBox="0 0 24 24">
          <path
            d="M12.5,0c1.3,0,2.6,0,3.9,0c0.1,1.5,0.6,3.1,1.8,4.2c1.1,1.1,2.7,1.6,4.2,1.8v4c-1.4-0.1-2.9-0.4-4.2-1
	c-0.6-0.3-1.1-0.6-1.6-0.9c0,2.9,0,5.8,0,8.8c-0.1,1.4-0.5,2.8-1.4,3.9c-1.3,1.9-3.6,3.2-5.9,3.2c-1.4,0.1-2.9-0.3-4.1-1
	c-2-1.2-3.4-3.4-3.7-5.7c0-0.5,0-1,0-1.5c0.2-1.9,1.1-3.7,2.6-5c1.7-1.4,4-2.1,6.1-1.7c0,1.5,0,3,0,4.4c-1-0.3-2.2-0.2-3,0.4
	c-0.6,0.4-1.1,1-1.4,1.8c-0.2,0.5-0.2,1.1-0.1,1.6c0.2,1.6,1.8,3,3.5,2.9c1.1,0,2.2-0.7,2.8-1.6c0.2-0.3,0.4-0.7,0.4-1.1
	c0.1-1.8,0.1-3.6,0.1-5.4C12.5,8.1,12.5,4,12.5,0z"
          />
        </svg>
      </div>
    </>
  );
};

export const LinkedInIcon = () => {
  return (
    <>
      <div className="social-icon">
        <svg x="0px" y="0px" viewBox="0 0 24 24">
          <path
            d="M20.4,20.5h-3.6v-5.6c0-1.3,0-3-1.9-3c-1.9,0-2.1,1.4-2.1,2.9v5.7H9.4V9h3.4v1.6h0c0.5-0.9,1.6-1.9,3.4-1.9
	c3.6,0,4.3,2.4,4.3,5.5L20.4,20.5L20.4,20.5z M5.3,7.4c-1.1,0-2.1-0.9-2.1-2.1c0-1.1,0.9-2.1,2.1-2.1c1.1,0,2.1,0.9,2.1,2.1
	C7.4,6.5,6.5,7.4,5.3,7.4z M7.1,20.5H3.6V9h3.6V20.5z M22.2,0H1.8C0.8,0,0,0.8,0,1.7v20.5c0,1,0.8,1.7,1.8,1.7h20.5
	c1,0,1.8-0.8,1.8-1.7V1.7C24,0.8,23.2,0,22.2,0L22.2,0z"
          />
        </svg>
      </div>
    </>
  );
};

export const TwitterIcon = () => {
  return (
    <>
      <div className="social-icon">
        <svg x="0px" y="0px" viewBox="0 0 24 24">
          <path
            d="M21.5,7.1c0,0.2,0,0.4,0,0.6c0,6.5-5,14-14,14v0c-2.7,0-5.3-0.8-7.5-2.2c2.6,0.3,5.2-0.4,7.3-2c-2.1,0-4-1.4-4.6-3.4
	c0.7,0.1,1.5,0.1,2.2-0.1C2.6,13.5,1,11.5,1,9.2V9.1c0.7,0.4,1.5,0.6,2.2,0.6C1,8.3,0.4,5.4,1.7,3.1c2.5,3.1,6.2,4.9,10.1,5.1
	c-0.6-2.7,1-5.3,3.7-5.9C17.2,2,19,2.5,20.2,3.8c1.1-0.2,2.2-0.6,3.1-1.2c-0.4,1.1-1.1,2.1-2.2,2.7c1-0.1,1.9-0.4,2.8-0.8
	C23.3,5.5,22.5,6.4,21.5,7.1z"
          />
        </svg>
      </div>
    </>
  );
};

export const FacebookIcon = () => {
  return (
    <>
      <div className="social-icon">
        <svg x="0px" y="0px" viewBox="0 0 24 24">
          <path
            d="M24,12.1c0-6.6-5.4-12-12-12S0,5.4,0,12.1c0,6,4.4,11,10.1,11.9v-8.4h-3v-3.5h3V9.4c0-3,1.8-4.7,4.5-4.7
	C16,4.8,17.3,5,17.3,5v3h-1.5c-1.5,0-2,0.9-2,1.9v2.2h3.3l-0.5,3.5h-2.8v8.4C19.6,23,24,18.1,24,12.1z"
          />
        </svg>
      </div>
    </>
  );
};

export const LinkShare = () => {
  return (
    <svg x="0px" y="0px" viewBox="0 0 50 50">
      <g>
        <path
          className="link-share-button"
          d="M32,15.5h6.4c2,0,3.7,1.6,3.7,3.7v24.3c0,2-1.6,3.7-3.7,3.7H11.6c-2,0-3.7-1.6-3.7-3.7V19.1
          c0-2,1.6-3.7,3.7-3.7H18"
        />
        <polyline
          className="link-share-button"
          points="17.6,10.4 25,3 32.4,10.4 	"
        />
        <line className="link-share-button" x1="25" y1="30.7" x2="25" y2="3" />
      </g>
    </svg>
  );
};

export const MenuButton = () => {
  return (
    <>
      <svg id="menu-burger" x="0px" y="0px" viewBox="0 0 50 50">
        <path
          className="no-close"
          d="M36.9,18.8H13.1c-0.9,0-1.5-0.7-1.5-1.5v0c0-0.9,0.7-1.5,1.5-1.5h23.8c0.9,0,1.5,0.7,1.5,1.5v0
	C38.5,18.2,37.8,18.8,36.9,18.8z"
        />
        <path
          className="on-close left"
          d="M36.9,26.5H13.1c-0.9,0-1.5-0.7-1.5-1.5v0c0-0.9,0.7-1.5,1.5-1.5h23.8c0.9,0,1.5,0.7,1.5,1.5v0
	C38.5,25.9,37.8,26.5,36.9,26.5z"
        />
        <path
          className="on-close right"
          d="M36.9,26.5H13.1c-0.9,0-1.5-0.7-1.5-1.5v0c0-0.9,0.7-1.5,1.5-1.5h23.8c0.9,0,1.5,0.7,1.5,1.5v0
	C38.5,25.9,37.8,26.5,36.9,26.5z"
        />
        <path
          className="no-close bottom"
          d="M36.9,34.3H13.1c-0.9,0-1.5-0.7-1.5-1.5l0,0c0-0.9,0.7-1.5,1.5-1.5h23.8c0.9,0,1.5,0.7,1.5,1.5l0,0
	C38.5,33.6,37.8,34.3,36.9,34.3z"
        />
      </svg>
    </>
  );
};

export const NextButton = () => {
  return (
    <>
      <svg className="next-button" x="0px" y="0px" viewBox="0 0 50 50">
        <g id="Layer_1">
          <path
            className="st0"
            d="M40.3,23.8L13.5,8.2c-1-0.6-2.2,0.1-2.2,1.2v31c0,1.1,1.2,1.8,2.2,1.2l26.9-15.5
          C41.3,25.7,41.3,24.3,40.3,23.8z"
          />
        </g>
        <g id="Layer_2" className="st1">
          <g className="st2">
            <path
              d="M25,2c12.7,0,23,10.3,23,23c0,12.7-10.3,23-23,23C12.3,48,2,37.7,2,25C2,12.3,12.3,2,25,2 M25,0L25,0C11.2,0,0,11.2,0,25
            v0c0,13.8,11.2,25,25,25h0c13.8,0,25-11.2,25-25v0C50,11.2,38.8,0,25,0L25,0z"
            />
          </g>
          <path
            className="st2"
            d="M36.9,18.8H13.1c-0.9,0-1.5-0.7-1.5-1.5v0c0-0.9,0.7-1.5,1.5-1.5h23.8c0.9,0,1.5,0.7,1.5,1.5v0
          C38.5,18.2,37.8,18.8,36.9,18.8z"
          />
          <path
            className="st2"
            d="M36.9,26.5H13.1c-0.9,0-1.5-0.7-1.5-1.5v0c0-0.9,0.7-1.5,1.5-1.5h23.8c0.9,0,1.5,0.7,1.5,1.5v0
          C38.5,25.9,37.8,26.5,36.9,26.5z"
          />
          <path
            className="st2"
            d="M36.9,26.5H13.1c-0.9,0-1.5-0.7-1.5-1.5v0c0-0.9,0.7-1.5,1.5-1.5h23.8c0.9,0,1.5,0.7,1.5,1.5v0
          C38.5,25.9,37.8,26.5,36.9,26.5z"
          />
          <path
            className="st2"
            d="M36.9,34.3H13.1c-0.9,0-1.5-0.7-1.5-1.5l0,0c0-0.9,0.7-1.5,1.5-1.5h23.8c0.9,0,1.5,0.7,1.5,1.5l0,0
          C38.5,33.6,37.8,34.3,36.9,34.3z"
          />
        </g>
      </svg>
    </>
  );
};

export const MapPin = () => {
  return (
    <svg x="0px" y="0px" viewBox="0 0 150 150">
      <path
        className="map-pin"
        d="M89.8,10.4C85.4,9,80.7,8.2,75.8,8.2c-14.2,0-26.9,6.4-35.3,16.5l21.8,18.3L89.8,10.4z M40.5,24.7
        c-6.7,8-10.8,18.4-10.8,29.7c0,8.7,1.7,15.7,4.6,22l28-33.3L40.5,24.7z M75.8,36.8c9.8,0,17.7,7.9,17.7,17.7c0,4.3-1.6,8.3-4.2,11.4
        c0,0,13.9-16.6,27.5-32.7c-5.6-10.8-15.3-19-27-22.7L62.3,43.1C65.5,39.2,70.4,36.8,75.8,36.8 M75.8,72c-9.8,0-17.7-7.9-17.7-17.7
        c0-4.3,1.5-8.3,4.1-11.3l-28,33.3C39,87,47,95.6,55.2,106.3l34.1-40.5C86.1,69.6,81.2,72,75.8,72 M88.6,117.4
        c15.4-24.1,33.3-35,33.3-63c0-7.7-1.9-14.9-5.2-21.3l-61.5,73.2c2.6,3.4,5.2,7.1,7.8,11.1c9.4,14.5,6.8,23.1,12.8,23.1
        C81.9,140.5,79.3,131.8,88.6,117.4"
      />
    </svg>
  );
};

export const PrevButton = () => {
  return (
    <>
      <svg x="0px" y="0px" viewBox="0 0 50 50" className="previous-button">
        <g id="Layer_1">
          <path
            className="st0"
            d="M12,26.2l26.9,15.5c1,0.6,2.2-0.1,2.2-1.2v-31c0-1.1-1.2-1.8-2.2-1.2L12,23.8C11.1,24.3,11.1,25.7,12,26.2z"
          />
        </g>
        <g id="Layer_2" className="st1">
          <g className="st2">
            <path
              d="M25,2c12.7,0,23,10.3,23,23c0,12.7-10.3,23-23,23C12.3,48,2,37.7,2,25C2,12.3,12.3,2,25,2 M25,0L25,0C11.2,0,0,11.2,0,25
			v0c0,13.8,11.2,25,25,25h0c13.8,0,25-11.2,25-25v0C50,11.2,38.8,0,25,0L25,0z"
            />
          </g>
          <path
            className="st2"
            d="M36.9,18.8H13.1c-0.9,0-1.5-0.7-1.5-1.5v0c0-0.9,0.7-1.5,1.5-1.5h23.8c0.9,0,1.5,0.7,1.5,1.5v0
		C38.5,18.2,37.8,18.8,36.9,18.8z"
          />
          <path
            className="st2"
            d="M36.9,26.5H13.1c-0.9,0-1.5-0.7-1.5-1.5v0c0-0.9,0.7-1.5,1.5-1.5h23.8c0.9,0,1.5,0.7,1.5,1.5v0
		C38.5,25.9,37.8,26.5,36.9,26.5z"
          />
          <path
            className="st2"
            d="M36.9,26.5H13.1c-0.9,0-1.5-0.7-1.5-1.5v0c0-0.9,0.7-1.5,1.5-1.5h23.8c0.9,0,1.5,0.7,1.5,1.5v0
		C38.5,25.9,37.8,26.5,36.9,26.5z"
          />
          <path
            className="st2"
            d="M36.9,34.3H13.1c-0.9,0-1.5-0.7-1.5-1.5l0,0c0-0.9,0.7-1.5,1.5-1.5h23.8c0.9,0,1.5,0.7,1.5,1.5l0,0
		C38.5,33.6,37.8,34.3,36.9,34.3z"
          />
        </g>
      </svg>
    </>
  );
};
