import React from "react";
// import { Script } from "gatsby";
// import { Link } from "gatsby";
import { MailchimpSignup } from "../mailchimp-signup/mailchimp-signup";
import SocialRow from "../social-row/social-row";
import "../mailchimp-signup/mailchimp-signup.scss";
import "./footer.scss";

const Footer = () => {
  return (
    <footer>
      <div className="footer-content">
        <div className="page-layout">
          <div className="footer-grid footer-top">
            <div className="footer-identity">
              {/* <img src={`../../atlfp-logo-can.png`} alt="ATL Film Party Logo" /> */}
              <MailchimpSignup />
            </div>
            <div></div>
            <div></div>
            <div className="footer-social">
              <div className="social-info">
                <h3>Follow us on your favorite social platform!</h3>
                {/* <p>
                  Get the latest news & updates from AFP straight to your
                  inbox
                </p> */}
              </div>
              <SocialRow />
            </div>
          </div>
          <div className="footer-grid footer-bottom">
            <div className="footer-copyright">
              <p>Copyright © 2023 ATLFilmParty, All rights reserved.</p>
            </div>
            {/* <div className="footer-love">
              <p>
                Straight outta ATL<span className="atl-ho"> ✌🏾</span>
              </p>
            </div> */}
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
